import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store";

interface myvar {
  data: Array<string>;
}

export interface HotelActionsInfo {
  myList: myvar;
  myDetails: Array<string>;
  errors: Array<string>;
}

@Module
export default class HotelActionsModule
    extends VuexModule
    implements HotelActionsInfo
{
  errors = [];
  myList = {} as myvar;
  myDetails = [];

  get hotelActionsList(): myvar {
    return this.myList;
  }

  get hotelActionsDetails(): Array<string> {
    return this.myDetails;
  }

  get getHotelActionsErrors(): Array<string> {
    return this.errors;
  }

  @Mutation
  [Mutations.HOTEL_ACTIONS_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_HOTEL_ACTIONS_LIST](payload) {
    this.myList = payload;
  }

  @Mutation
  [Mutations.SET_HOTEL_ACTIONS_DETAILS](payload) {
    this.myDetails = payload;
  }

  @Action
  [Actions.HOTEL_ACTIONS_LIST](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("/hotelactions/getList", payload)
          .then(({ data }) => {
            //console.log(data);
            payload.data = data.data;
            this.context.commit(Mutations.SET_HOTEL_ACTIONS_LIST, payload);
            resolve(payload);
          })
          .catch(({ response }) => {
            reject();
          });
    });
  }

  @Action
  [Actions.HOTEL_ACTIONS_DETAILS](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("/hotelactions/get", payload)
          .then(({ data }) => {
            payload = data.data;
            console.log(payload);
            this.context.commit(Mutations.SET_HOTEL_ACTIONS_DETAILS, payload);
            resolve();
          })
          .catch(({ response }) => {
            reject();
          });
    });
  }

  @Action
  [Actions.HOTEL_ACTIONS_INSERT](credentials) {
    console.log("INSERTTTT");
    return new Promise<void>((resolve, reject) => {
      ApiService.post("/hotelactions/insert", credentials)
          //ApiService2.post("/fup/put.php", credentials)
          .then(({ data }) => {
            if (data.status == 201) {
              if (credentials.HotelID) {
                const payload = {
                  HotelID: credentials.HotelID,
                };
                store.dispatch(Actions.HOTEL_ACTIONS_LIST, payload);
              }
              resolve();
            } else {
              const myError = [
                "ERROR: " + data.messages + " (" + data.code + ")",
              ];
              this.context.commit(Mutations.HOTEL_ACTIONS_ERROR, myError);
              reject();
            }
          })
          .catch(({ response }) => {
            const myError = [
              "ERROR: OTEL AKSİYON Ekleme işlemi sırasında beklenmedik bir hata oluştu.",
            ];
            this.context.commit(Mutations.HOTEL_ACTIONS_ERROR, myError);
            reject();
          });
    });
  }

  @Action
  [Actions.HOTEL_ACTIONS_EDIT](credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("/hotelactions/update", credentials)
          .then(({ data }) => {
            if (data.status == 200) {
              if (credentials.HotelID) {
                const payload = {
                  HotelID: credentials.HotelID,
                };
                store.dispatch(Actions.HOTEL_ACTIONS_LIST, payload);
              }
              resolve();
            } else {
              const myError = [
                "ERROR: " + data.messages + " (" + data.code + ")",
              ];
              this.context.commit(Mutations.HOTEL_ACTIONS_ERROR, myError);
              reject();
            }
          })
          .catch(({ response }) => {
            const myError = [
              "ERROR: OTEL AKSİYON güncelleme işlemi sırasında beklenmedik bir hata oluştu.",
            ];
            this.context.commit(Mutations.HOTEL_ACTIONS_ERROR, myError);
            reject();
          });
    });
  }

  @Action
  [Actions.HOTEL_ACTIONS_DELETE](credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("/hotelactions/delete", credentials)
          .then(({ data }) => {
            if (data.status == 200) {
              if (credentials.HotelID) {
                const payload = {HotelID: credentials.HotelID,};
                store.dispatch(Actions.HOTEL_ACTIONS_LIST, payload);
              }
              resolve();
            } else {
              const myError = [
                "ERROR: " + data.messages + " (" + data.code + ")",
              ];
              this.context.commit(Mutations.HOTEL_ACTIONS_ERROR, myError);
              reject();
            }
          })
          .catch(({ response }) => {
            const myError = [
              "ERROR: OTEL AKSİYON Silme işlemi sırasında beklenmedik bir hata oluştu.",
            ];
            this.context.commit(Mutations.HOTEL_ACTIONS_ERROR, myError);
            reject();
          });
    });
  }

  @Action
  [Actions.HOTEL_ACTIONS_ORDER](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("/hotelactions/order", payload)
          .then(({ data }) => {
            if (data.status == 200) {
              if (payload.HotelID) {
                store.dispatch(Actions.HOTEL_ACTIONS_LIST, {HotelID: payload.HotelID,});
              }
              resolve();
            } else {
              const myError = ["ERROR: " + data.messages + " (" + data.code + ")",];
              this.context.commit(Mutations.HOTEL_ACTIONS_ERROR, myError);
              reject();
            }
          })
          .catch(({ response }) => {
            const myError = [
              "ERROR: OTEL AKSİYON Silme işlemi sırasında beklenmedik bir hata oluştu.",
            ];
            this.context.commit(Mutations.HOTEL_ACTIONS_ERROR, myError);
            reject();
          });
    });
  }


}
