import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";

interface myvar {
    data: Array<string>;
}

export interface ReportsInfo {
    myAccountSaleList: myvar;
    myProductSaleList: myvar;
    myAccountMounthlyList: myvar;
}

@Module
export default class ReportsModule extends VuexModule implements ReportsInfo {
    myAccountSaleList = {} as myvar;
    myProductSaleList = {} as myvar;
    myAccountMounthlyList = {} as myvar;
    myHotelForCast= {} as myvar;

    get accountsaleList(): myvar {
        return this.myAccountSaleList;
    }

    get hotelForCastList(): myvar {
        return this.myHotelForCast;
    }

    get productsaleList(): myvar {
        return this.myProductSaleList;
    }

    get accountmounthlyList(): myvar {
        return this.myAccountMounthlyList;
    }


    @Mutation
    [Mutations.SET_ACCOUNT_SALE_LIST](payload) {
        this.myAccountSaleList = payload;
    }

    @Mutation
    [Mutations.SET_HOTEL_FORCAST_LIST](payload) {
        this.myHotelForCast = payload;
    }

    @Mutation
    [Mutations.SET_PRODUCT_SALE_LIST](payload) {
        this.myProductSaleList = payload;
    }

    @Mutation
    [Mutations.SET_ACCOUNT_MOUNTHLY_LIST](payload) {
        this.myAccountMounthlyList = payload;
    }



    @Action
    [Actions.ACCOUNT_SALE_LIST](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/reports/accountsale", payload)
                .then(({ data }) => {
                    //console.log(data);
                    payload = data.data;
                    this.context.commit(Mutations.SET_ACCOUNT_SALE_LIST, payload);
                    resolve(payload);
                })
                .catch(({ response }) => {
                    reject();
                });
        });
    }

    @Action
    [Actions.PRODUCT_SALE_LIST](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/reports/productSale", payload)
                .then(({ data }) => {
                    //console.log(data);
                    payload = data.data;
                    this.context.commit(Mutations.SET_PRODUCT_SALE_LIST, payload);
                    resolve(payload);
                })
                .catch(({ response }) => {
                    reject();
                });
        });
    }

    @Action
    [Actions.ACCOUNT_MOUNTHLY_LIST](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/reports/getMounthlyAccountReport", payload)
                .then(({ data }) => {
                    //console.log(data);
                    payload = data.data;
                    this.context.commit(Mutations.SET_ACCOUNT_MOUNTHLY_LIST, payload);
                    resolve(payload);
                })
                .catch(({ response }) => {
                    reject();
                });
        });
    }

    @Action
    [Actions.HOTEL_FORCAST_LIST](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/reports/accountsale", payload)
                .then(({ data }) => {
                    //console.log(data);
                    payload = data.data;
                    this.context.commit(Mutations.SET_HOTEL_FORCAST_LIST, payload);
                    resolve(payload);
                })
                .catch(({ response }) => {
                    reject();
                });
        });
    }

   
}