import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";
import store from "@/store";

interface myvar {
    data: Array<string>;
}

export interface LogMainsInfo {
    myList: myvar;
}

@Module
export default class LogMainsModule extends VuexModule implements LogMainsInfo {
    myList = {} as myvar;

    get logmainsList(): myvar {
        return this.myList;
    }


    @Mutation
    [Mutations.SET_LOG_MAINS_LIST](payload) {
        this.myList = payload;
    }


    @Action
    [Actions.LOG_MAINS_LIST](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/logmains/getList", payload)
                .then(({ data }) => {
                    //console.log(data);
                    payload.data = data.data;
                    this.context.commit(Mutations.SET_LOG_MAINS_LIST, payload);
                    resolve(payload);
                })
                .catch(({ response }) => {
                    reject();
                });
        });

    }

}