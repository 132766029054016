enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  FILE_UPLOAD = "putFileUpload",
  FILES_LIST = "getFilesList",
  FILES_DETAILS = "getFilesDetails",
  FILES_INSERT = "filesInsert",
  FILES_EDIT = "filesEdit",
  FILES_ORDER = "filesOrder",
  FILES_DELETE = "filesDelete",
  FILES_SELECTED = "filesSelected",
  EXCEL_PARSE_TITLE = "excelParseTitle",
  EXCEL_PARSE_RESULT = "excelParseResult",
  USER_LIST = "getUserList",
  USER_DETAILS = "getUserDetails",
  USER_INSERT = "userInsert",
  USER_EDIT = "userEdit",
  USER_DELETE = "userDelete",

  POS_PROCESS_START_PROCESS = "posProcessStartProcess",
  POS_PROCESS_END_PROCESS = "posProcessEndProcess",
  POS_PROCESS_SEND_FORM_NOMUPAY = "posProcessSendFormNomupay",

  API_LIST = "apiList",
  API_SEARCH = "apiSearch",
  API_ADD_ITEM = "apiAddItem",
  API_GET_PACKAGE_RESULT = "apiGetPackageResult",
  API_GET_TRANSFERS = "apiGetTransfers",

  ACCOUNT_LIST = "getAccountList",
  ACCOUNT_LIST_ALL = "getAccountListAll",
  ACCOUNT_DETAILS = "getAccountDetails",
  ACCOUNT_INSERT = "accountInsert",
  ACCOUNT_EDIT = "accountEdit",
  ACCOUNT_DELETE = "accountDelete",

  ACCOUNT_PRODUCT_LIST = "getAccountProductList",
  ACCOUNT_PRODUCT_LIST_ALL = "getAccountProductListAll",
  ACCOUNT_PRODUCT_DETAILS = "getAccountProductDetails",
  ACCOUNT_PRODUCT_INSERT = "accountProductInsert",
  ACCOUNT_PRODUCT_EDIT = "accountProductEdit",
  ACCOUNT_PRODUCT_DELETE = "accountProductDelete",

  PAYMENT_LIST = "paymentList",
  PAYMENT_DETAILS = "paymentDetails",
  PAYMENT_INSERT = "paymentInsert",
  PAYMENT_EDIT = "paymentEdit",
  PAYMENT_DELETE = "paymentDelete",

  RESERVATION_PAYMENTS_LIST = "reservationPaymentsList",
  RESERVATION_PAYMENTS_DETAILS = "reservationPaymentsDetails",
  RESERVATION_PAYMENT_DELETE = "reservationPaymentDelete",

  REMARK_LIST = "remarkList",
  REMARK_DETAILS = "remarkDetails",
  REMARK_INSERT = "remarkInsert",
  REMARK_EDIT = "remarkEdit",
  REMARK_DELETE = "remarkDelete",

  HOTEL_LIST = "getHotelList",
  HOTEL_LIST_ALL = "getHotelListAll",
  HOTEL_LIST_ALL_TO = "getHotelListAllTo",
  HOTEL_DETAILS = "getHotelDetails",
  HOTEL_INSERT = "hotelInsert",
  HOTEL_EDIT = "hotelEdit",
  HOTEL_DELETE = "hotelDelete",
  HOTEL_ROOMS_LIST = "getHotelRoomsList",
  HOTEL_ROOMS_DETAILS = "getHotelRoomsDetails",
  HOTEL_ROOMS_INSERT = "hotelRoomsInsert",
  HOTEL_ROOMS_EDIT = "hotelRoomsEdit",
  HOTEL_ROOMS_DELETE = "hotelRoomsDelete",
  HOTEL_CONCEPTS_LIST = "getHotelConceptsList",
  HOTEL_CONCEPTS_LIST2 = "getHotelConceptsList2",
  HOTEL_CONCEPTS_DETAILS = "getHotelConceptsDetails",
  HOTEL_CONCEPTS_INSERT = "hotelConceptsInsert",
  HOTEL_CONCEPTS_EDIT = "hotelConceptsEdit",
  HOTEL_CONCEPTS_DELETE = "hotelConceptsDelete",
  HOTEL_ROOM_TYPES_LIST = "getHotelRoomTypesList",
  HOTEL_ROOM_TYPES_DETAILS = "getHotelRoomTypesDetails",
  HOTEL_ROOM_TYPES_INSERT = "hotelRoomTypesInsert",
  HOTEL_ROOM_TYPES_EDIT = "hotelRoomTypesEdit",
  HOTEL_ROOM_TYPES_DELETE = "hotelRoomTypesDelete",
  HOTEL_FACTSHEETS_LIST = "getHotelFactSheetsList",
  HOTEL_FACTSHEETS_DETAILS = "getHotelFactSheetsDetails",
  HOTEL_FACTSHEETS_INSERT = "hotelFactSheetsInsert",
  HOTEL_FACTSHEETS_EDIT = "hotelFactSheetsEdit",
  HOTEL_FACTSHEETS_DELETE = "hotelFactSheetsDelete",
  HOTEL_FACTSHEET_ITEMS_LIST = "getHotelFactSheetItemsList",
  HOTEL_FACTSHEET_ITEMS_SELECTED = "getHotelFactSheetItemsSelectedList",
  HOTEL_FACTSHEET_ITEMS_DETAILS = "getHotelFactSheetItemsDetails",
  HOTEL_FACTSHEET_ITEMS_INSERT = "hotelFactSheetItemsInsert",
  HOTEL_FACTSHEET_ITEMS_EDIT = "hotelFactSheetItemsEdit",
  HOTEL_FACTSHEET_ITEMS_DELETE = "hotelFactSheetItemsDelete",
  HOTEL_FACTSHEET_ITEMS_ORDER = "hotelFactSheetItemsOrder",
  HOTEL_CONCEPT_TYPES_LIST = "getHotelConceptTypesList",
  HOTEL_CONCEPT_TYPES_DETAILS = "getHotelConceptTypesDetails",
  HOTEL_CONCEPT_TYPES_INSERT = "hotelConceptTypesInsert",
  HOTEL_CONCEPT_TYPES_EDIT = "hotelConceptTypesEdit",
  HOTEL_CONCEPT_TYPES_DELETE = "hotelConceptTypesDelete",
  HOTEL_OVER_COMMISSION_LIST = "getHotelOverCommissionList",
  HOTEL_OVER_COMMISSION_DETAILS = "getHotelOverCommissionDetails",
  HOTEL_OVER_COMMISSION_INSERT = "hotelOverCommissionInsert",
  HOTEL_OVER_COMMISSION_EDIT = "hotelOverCommissionEdit",
  HOTEL_OVER_COMMISSION_DELETE = "hotelOverCommissionDelete",

  HOTEL_PROFITS_LIST = "getHotelProfitsList",
  HOTEL_PROFITS_DETAILS = "getHotelProfitsDetails",
  HOTEL_PROFITS_INSERT = "hotelProfitsInsert",
  HOTEL_PROFITS_EDIT = "hotelProfitsEdit",
  HOTEL_PROFITS_DELETE = "hotelProfitsDelete",
  HOTEL_PROFITS_ORDER = "getHotelProfitsOrder",

  HOTEL_KICKBACKS_LIST = "getHotelKickBacksList",
  HOTEL_KICKBACKS_DETAILS = "getHotelKickBacksDetails",
  HOTEL_KICKBACKS_INSERT = "hotelKickBacksInsert",
  HOTEL_KICKBACKS_EDIT = "hotelKickBacksEdit",
  HOTEL_KICKBACKS_DELETE = "hotelKickBacksDelete",

  HOTEL_AVAILABLE_LIST = "getHotelAvailableList",
  HOTEL_AVAILABLE_STATUS = "getHotelAvailableStatus",
  HOTEL_AVAILABLE_DETAILS = "getHotelAvailableDetails",
  HOTEL_AVAILABLE_INSERT = "hotelAvailableInsert",
  HOTEL_AVAILABLE_EDIT = "hotelAvailableEdit",
  HOTEL_AVAILABLE_DELETE = "hotelAvailableDelete",
  HOTEL_AVAILABLE_ADD_DATES = "hotelAvailableAddDates",

  HOTEL_ALLOCATIONS_LIST = "getHotelAllocationsList",
  HOTEL_ALLOCATIONS_DETAILS = "getHotelAllocationsDetails",
  HOTEL_ALLOCATIONS_INSERT = "hotelAllocationsInsert",
  HOTEL_ALLOCATIONS_EDIT = "hotelAllocationsEdit",
  HOTEL_ALLOCATIONS_DELETE = "hotelAllocationsDelete",

  HOTEL_AD_LIST = "getHotelAdList",
  HOTEL_AD_DETAILS = "getHotelAdDetails",
  HOTEL_AD_INSERT = "hotelAdInsert",
  HOTEL_AD_EDIT = "hotelAdEdit",
  HOTEL_AD_DELETE = "hotelAdDelete",

  HOTEL_ACTIONS_LIST = "getHotelActionsList",
  HOTEL_ACTIONS_DETAILS = "getHotelActionsDetails",
  HOTEL_ACTIONS_INSERT = "hotelActionsInsert",
  HOTEL_ACTIONS_EDIT = "hotelActionsEdit",
  HOTEL_ACTIONS_DELETE = "hotelActionsDelete",
  HOTEL_ACTIONS_ORDER = "hotelActionsOrder",

  HOTEL_ROOM_PRICE_LIST = "getHotelRoomPriceList",
  HOTEL_ROOM_PRICE_DETAILS = "getHotelRoomPriceDetails",
  HOTEL_ROOM_PRICE_INSERT = "hotelRoomPriceInsert",
  HOTEL_ROOM_PRICE_EDIT = "hotelRoomPriceEdit",
  HOTEL_ROOM_PRICE_DELETE = "hotelRoomPriceDelete",
  HOTEL_ROOM_PRICE_DELETE_BULK = "hotelRoomPriceDeleteBulk",

  FACTSHEETS_LIST = "getFactSheetsList",
  FACTSHEETS_DETAILS = "getFactSheetsDetails",
  FACTSHEETS_INSERT = "factSheetsInsert",
  FACTSHEETS_EDIT = "factSheetsEdit",
  FACTSHEETS_DELETE = "factSheetsDelete",
  FACTSHEETS_SELECTED = "factSheetsSelected",
  FACTSHEET_ITEMS_LIST = "getFactSheetItemsList",
  FACTSHEET_ITEMS_DETAILS = "getFactSheetItemsDetails",
  FACTSHEET_ITEMS_INSERT = "factSheetItemsInsert",
  FACTSHEET_ITEMS_EDIT = "factSheetItemsEdit",
  FACTSHEET_ITEMS_DELETE = "factSheetItemsDelete",
  FACTSHEET_ITEMS_SELECTED = "factSheetItemsSelected",
  ACCOUNT_TAGS_LIST = "getAccountTagsList",
  ACCOUNT_TAGS_DETAILS = "getAccountTagsDetails",
  ACCOUNT_TAGS_INSERT = "accountTagsInsert",
  ACCOUNT_TAGS_EDIT_LIST = "accountTagsEditList",
  ACCOUNT_TAGS_EDIT = "accountTagsEdit",
  ACCOUNT_TAGS_DELETE = "accountTagsDelete",
  RESERVATION_TAGS_LIST = "getReservationTagsList",
  RESERVATION_TAGS_DETAILS = "getReservationTagsDetails",
  RESERVATION_TAGS_INSERT = "reservationTagsInsert",
  RESERVATION_TAGS_EDIT_LIST = "reservationTagsEditList",
  RESERVATION_TAGS_EDIT = "reservationTagsEdit",
  RESERVATION_TAGS_DELETE = "reservationTagsDelete",
  PRICE_LISTS_LIST = "getPriceListsList",
  PRICE_LISTS_SELECTED = "priceListsSelected",
  PRICE_LISTS_DETAILS = "getPriceListsDetails",
  PRICE_LISTS_INSERT = "priceListsInsert",
  PRICE_LISTS_EDIT = "priceListsEdit",
  PRICE_LISTS_DELETE = "priceListsDelete",
  PRICE_LISTS_ORDER = "priceListsOrder",
  PRICE_LIST_ITEMS_LIST = "getPriceListItemsList",
  PRICE_LIST_ITEMS_DETAILS = "getPriceListItemsDetails",
  PRICE_LIST_ITEMS_SELECTED = "priceListItemsSelected",
  PRICE_LIST_ITEMS_INSERT = "priceListItemsInsert",
  PRICE_LIST_ITEMS_EDIT = "priceListItemsEdit",
  PRICE_LIST_ITEMS_DELETE = "priceListItemsDelete",
  PRICE_LIST_ITEMS_ORDER = "priceListItemsOrder",
  MICE_REQUESTS_LIST = "getMiceRequestsList",
  MICE_REQUESTS_SELECTED = "miceRequestsSelected",
  MICE_REQUESTS_DETAILS = "getMiceRequestsDetails",
  MICE_REQUESTS_INSERT = "miceRequestsInsert",
  MICE_REQUESTS_EDIT = "miceRequestsEdit",
  MICE_REQUESTS_DELETE = "miceRequestsDelete",
  SUPPLIERS_LIST = "getSuppliersList",
  SUPPLIERS_LIST_ALL= "getSuppliersListAll",
  SUPPLIERS_DETAILS = "getSuppliersDetails",
  SUPPLIERS_INSERT = "suppliersInsert",
  SUPPLIERS_EDIT = "suppliersEdit",
  SUPPLIERS_DELETE = "suppliersDelete",
  AIRLINE_LIST = "getAirlineList",
  AIRLINE_DETAILS = "getAirlinesDetails",
  AIRLINE_INSERT = "airlineInsert",
  AIRLINE_EDIT = "airlineEdit",
  AIRLINE_DELETE = "airlineDelete",
  AIRLINE_CLASSES_LIST = "getAirlineClassesList",
  AIRLINE_CLASSES_LIST_ALL= "getAirportClassesListAll",
  AIRLINE_CLASSES_DETAILS = "getAirlinesClassesDetails",
  AIRLINE_CLASSES_INSERT = "airlineClassesInsert",
  AIRLINE_CLASSES_EDIT = "airlineClassesEdit",
  AIRLINE_CLASSES_DELETE = "airlineClassesDelete",
  AIRPORTS_LIST = "getAirportsList",
  AIRPORTS_LIST_ALL= "getAirportsListAll",
  AIRPORTS_DETAILS = "getAirportsDetails",
  AIRPORTS_INSERT = "airportsInsert",
  AIRPORTS_EDIT = "airportsEdit",
  AIRPORTS_DELETE = "airportsDelete",
  FLIGHT_EXTRA_LIST = "getFlightExtrasList",
  FLIGHT_EXTRA_DETAILS = "getFlightExtrasDetails",
  FLIGHT_EXTRA_INSERT = "flightExtrasInsert",
  FLIGHT_EXTRA_EDIT = "flightExtrasEdit",
  FLIGHT_EXTRA_DELETE = "flightExtrasDelete",

  FLIGHT_LIST = "flightsList",
  FLIGHT_DETAILS = "flightsDetails",

  FLIGHT_RESERVATION_STEPS_BASKET = "flightReservationStepsBasket",
  FLIGHT_RESERVATION_SEARCH_PARAMS = "flightReservationSearchParams",
  FLIGHT_RESERVATION_LIST = "flightReservationList",
  FLIGHT_RESERVATION_ADD = "flightReservationAdd",
  FLIGHT_RESERVATION_ADD_INSTALLMENT = "flightReservationAddInstallmen",
  FLIGHT_RESERVATION_SEARCH_CODE = "flightReservationSearchCode",
  FLIGHT_RESERVATION_SUMMARY_HOTEL_RATE = "flightReservationSummaryHotelRate",
  FLIGHT_RESERVATION_PACKAGE_RESULT = "flightReservationPackageResult",
  FLIGHT_RESERVATION_TRANSFERS = "flightReservationTransfers",
  FLIGHT_RESERVATION_MAKE_RESERVATION = "flightReservationMakeReservation",
  FLIGHT_RESERVATION_SEND_EMAIL = "flightReservationSendEmail",

  FLIGHT_RESERVATION_CUR_STEP = "flightReservationCurStep",
  FLIGHT_RESERVATION_CUR_NEXT = "flightReservationCurNext",
  FLIGHT_RESERVATION_CUR_PREV = "flightReservationCurPrev",

  FLIGHT_RESERVATION_IS_SEARCHING = "flightReservationIsSearching",

  BANKS_LIST = "getBanksList",
  BANKS_DETAILS = "getBanksDetails",
  BANKS_INSERT = "banksInsert",
  BANKS_EDIT = "banksEdit",
  BANKS_DELETE = "banksDelete",
  BANKPOS_LIST = "getBankPosList",
  BANKPOS_DETAILS = "getBankPosDetails",
  BANKPOS_INSERT = "bankPosInsert",
  BANKPOS_EDIT = "bankPosEdit",
  BANKPOS_DELETE = "bankPosDelete",
  BANKPOS_INSTALLMENT_LIST = "getBankPosInstallmentList",
  BANKPOS_INSTALLMENT_WITH_COMMISSION_LIST = "getBankPosInstallmentWithCommissionList",
  BANKPOS_INSTALLMENT_DETAILS = "getBankPosInstallmentDetails",
  BANKPOS_INSTALLMENT_INSERT = "bankPosInstallmentInsert",
  BANKPOS_INSTALLMENT_EDIT = "bankPosInstallmentEdit",
  BANKPOS_INSTALLMENT_DELETE = "bankPosInstallmentDelete",
  BANKACCOUNTS_LIST = "getBankAccountsList",
  BANKACCOUNTS_DETAILS = "getBankAccountsDetails",
  BANKACCOUNTS_INSERT = "bankAccountsInsert",
  BANKACCOUNTS_EDIT = "bankAccountsEdit",
  BANKACCOUNTS_DELETE = "bankAccountsDelete",
  COMPANY_CREDIT_CARD_LIST = "getCompanyCreditCardList",
  COMPANY_CREDIT_CARD_DETAILS = "getCompanyCreditCardDetails",
  COMPANY_CREDIT_CARD_INSERT = "companyCreditCardInsert",
  COMPANY_CREDIT_CARD_EDIT = "companyCreditCardEdit",
  COMPANY_CREDIT_CARD_DELETE = "companyCreditCardDelete",
  CUR_RATE_LIST = "getCurRateList",
  CUR_RATE_LAST = "getCurRateLast",
  CUR_RATE_DETAILS = "getCurRateDetails",
  CUR_RATE_INSERT = "curRateInsert",
  CUR_RATE_EDIT = "curRateEdit",
  CUR_RATE_DELETE = "curRateDelete",
  /************RESERVATION START************/
  /************RESERVATION START************/
  /************RESERVATION START************/
  RESERVATIONS_LIST = "getReservationsList",
  RESERVATIONS_LIST2 = "getReservationsList2",
  RESERVATIONS_NONINVOICES_LIST = "getReservationsNoninvoicesList",
  RESERVATIONS_NONPAYMENT_LIST = "getReservationsNonPaymentList",
  RESERVATIONS_TOTALS_BY_ACCOUNTS = "getReservationsTotalByAccounts",
  RESERVATIONS_DETAILS = "getReservationsDetails",
  RESERVATIONS_INSERT = "reservationsInsert",
  RESERVATIONS_PRODUCT_INSERT = "reservationsProductInsert",
  RESERVATIONS_PRODUCT_UPDATE = "reservationsProductUpdate",
  RESERVATIONS_PRODUCT_DELETE = "reservationProductDelete",
  RESERVATIONS_ALL_DETAILS = "reservationAllDetails",
  RESERVATIONS_EDIT = "reservationsEdit",
  RESERVATIONS_DELETE = "reservationsDelete",
  RESERVATIONS_KPLUS_PROCESS_START = "getReservationsKplusProcessStart",
  RESERVATIONS_KPLUS_PROCESS_START_BOOKING = "getReservationsKplusProcessStartBooking",
  RESERVATIONS_CALC_DEPTS = "reservationsCalcDepts",

  RESERVATION_PRODUCTS_LIST = "getReservationProductsList",
  RESERVATION_PRODUCTS_LIST_2 = "getReservationProductsList2",
  RESERVATION_PRODUCTS_NONINVOICES_LIST = "getReservationProductsNoninvoicesList",
  RESERVATION_PRODUCTS_CONFIRM_LIST = "getReservationProductsConfirmList",
  RESERVATION_TOTALS_BY_DASHBOARD = "getReservationTotalsByDashboard",
  RESERVATION_PRODUCTS_OPERATION_TRANSFER_LIST = "getReservationProductsOperationTransferList",
  RESERVATION_PRODUCTS_DETAILS = "getReservationProductsDetails",
  RESERVATION_PRODUCTS_INSERT = "reservationProductsInsert",
  RESERVATION_PRODUCTS_INSERT_MANUAL = "reservationProductsInsertManual",
  RESERVATION_PRODUCTS_EDIT = "reservationProductsEdit",
  RESERVATION_PRODUCTS_DELETE = "reservationProductsDelete",
  /************RESERVATION END************/
  /************RESERVATION END************/
  /************RESERVATION END************/

  /*****MAKE MANUEl RESERVATIONS START****/
  /*****MAKE MANUEl RESERVATIONS START****/
  /*****MAKE MANUEl RESERVATIONS START****/
  MAKE_MANUEL_RESERVATIONS = "makeManuelReservations",
  /*****MAKE MANUEl RESERVATIONS END****/
  /*****MAKE MANUEl RESERVATIONS END****/
  /*****MAKE MANUEl RESERVATIONS END****/

  TAGS_LIST = "getTagsList",
  TAGS_DETAILS = "getTagsDetails",
  TAGS_INSERT = "tagsInsert",
  TAGS_EDIT = "tagsEdit",
  TAGS_DELETE = "tagsDelete",
  COMPANY_LIST = "getCompanyList",
  COMPANY_BRANCHES_LIST = "getCompanyBranchesList",
  COMPANY_DEPARTMENTS_LIST = "getCompanyDepartmentsList",
  HOTEL_CHAIN_LIST = "getHotelChainList",
  REGIONS_LIST = "getRegionsList",
  MARKETS_LIST = "getMarketsList",
  MARKETS_LIST_CACHE = "getMarketsListCache",
  LANGUAGES_LIST = "getLanguagesList",
  LANGUAGES_LIST_CACHE = "getLanguagesListCache",
  COMPANY_TITLES_LIST = "getCompanyTitleList",
  CITY_LIST = "getCityList",
  COUNTRY_LIST = "getCountryList",
  EDITOR_CONTENT = "getEditorContent",
  SYSTEM_USER_GROUP_LISTALL = "getSystemUserGroupListAll",


  /********************/
  HOTEL_TEST_LIST = "getHotelTestList",
  HOTEL_TEST_DETAILS = "getHotelTestDetails",
  HOTEL_TEST_INSERT = "hotelTestInsert",
  HOTEL_TEST_EDIT = "hotelTestEdit",
  HOTEL_TEST_DELETE = "hotelTestDelete",
  /********************/

  TRANSFER_LIST = "getTransferList",
  TRANSFER_DETAILS = "getTransferDetails",
  TRANSFER_INSERT = "transferInsert",
  TRANSFER_EDIT = "transferEdit",
  TRANSFER_DELETE = "transferDelete",

  TRANSFER_COMPANY_LIST = "getTransferCompanyList",
  TRANSFER_COMPANY_DETAILS = "getTransferCompanyDetails",
  TRANSFER_COMPANY_INSERT = "transferCompanyInsert",
  TRANSFER_COMPANY_EDIT = "transferCompanyEdit",
  TRANSFER_COMPANY_DELETE = "transferCompanyDelete",

  TRANSFER_CARS_LIST = "getTransferCarsList",
  TRANSFER_CARS_DETAILS = "getTransferCarsDetails",
  TRANSFER_CARS_INSERT = "transferCarsInsert",
  TRANSFER_CARS_EDIT = "transferCarsEdit",
  TRANSFER_CARS_DELETE = "transferCarsDelete",

  TRANSFER_OPERATIONS_LIST = "transferOperationsList",
  TRANSFER_OPERATIONS_DETAILS = "transferOperationsDetails",
  TRANSFER_OPERATIONS_INSERT = "transferOperationsInsert",
  TRANSFER_OPERATIONS_EDIT = "transferOperationsEdit",
  TRANSFER_OPERATIONS_DELETE = "transferOperationsDelete",
  TRANSFER_OPERATIONS_ADD = "transferOperationsAdd",

  TRANSFER_PRICES_LIST = "getTransferPricesList",
  TRANSFER_PRICES_DETAILS = "getTransferPricesDetails",
  TRANSFER_PRICES_INSERT = "transferPricesInsert",
  TRANSFER_PRICES_EDIT = "transferPricesEdit",
  TRANSFER_PRICES_DELETE = "transferPricesDelete",

  TRANSFER_LOCATIONS_LIST = "getTransferLocationsList",
  TRANSFER_LOCATIONS_DETAILS = "getTransferLocationsDetails",
  TRANSFER_LOCATIONS_INSERT = "transferLocationsInsert",
  TRANSFER_LOCATIONS_EDIT = "transferLocationsEdit",
  TRANSFER_LOCATIONS_DELETE = "transferLocationsDelete",

  TRANSFER_CARCOSTS_LIST = "getTransferCarCostsList",
  TRANSFER_CARCOSTS_DETAILS = "getTransferCarCostsDetails",
  TRANSFER_CARCOSTS_INSERT = "transferCarCostsInsert",
  TRANSFER_CARCOSTS_EDIT = "transferCarCostsEdit",
  TRANSFER_CARCOSTS_DELETE = "transferCarCostsDelete",

  INVOICES_LIST = "getInvoicesList",
  INVOICES_DETAILS = "getInvoicesDetails",
  INVOICES_INSERT = "invoicesInsert",
  INVOICES_INSERT_WITH_ITEMS = "invoicesInsertWithItems",
  INVOICES_EDIT = "invoicesEdit",
  INVOICES_DELETE = "invoicesDelete",
  INVOICES_GETVOUCHERS = "invoicesGetVouchers",

  INVOICE_RETURNS_LIST = "getInvoiceReturnsList",
  INVOICE_RETURNS_DETAILS = "getInvoiceReturnsDetails",
  INVOICE_RETURNS_BULKINSERT = "invoiceReturnsBulkInsert",

  ACCOUNTING_CODE_LIST = "getAccountingCodeList",
  ACCOUNTING_CODE_DETAILS = "getAccountingCodeDetails",
  ACCOUNTING_CODE_INSERT = "accountingCodeInsert",
  ACCOUNTING_CODE_EDIT = "accountingCodeEdit",
  ACCOUNTING_CODE_DELETE = "accountingCodeDelete",

  EXPORT_TO_PDF_AND_SEND_EMAIL = "exportToPdfAndSendEmail",
  EXPORT_TO_PDF_SET_EMAIL_STATUS = "setExportToPdfEmailStatus",

  INVOICE_ITEMS_LIST = "getInvoiceItemsList",
  INVOICE_ITEMS_DETAILS = "getInvoiceItemsDetails",
  INVOICE_ITEMS_INSERT = "invoiceItemsInsert",
  INVOICE_ITEMS_EDIT = "invoiceItemsEdit",
  INVOICE_ITEMS_DELETE = "invoiceItemsDelete",

  VAT_RATES_LIST = "getVatRatesList",
  VAT_RATES_DETAILS = "getVatRatesDetails",
  VAT_RATES_INSERT = "vatRatesInsert",
  VAT_RATES_EDIT = "vatRatesEdit",
  VAT_RATES_DELETE = "vatRatesDelete",

  ACCOUNTING_COST_CENTERS_LIST = "getAccountingCostCentersList",
  ACCOUNTING_COST_CENTERS_DETAILS = "getAccountingCostCentersDetails",
  ACCOUNTING_COST_CENTERS_INSERT = "accountingCostCentersInsert",
  ACCOUNTING_COST_CENTERS_EDIT = "accountingCostCentersEdit",
  ACCOUNTING_COST_CENTERS_DELETE = "accountingCostCentersDelete",

  ACCOUNT_KICKBACK_LIST = "getAccountKickBackList",
  ACCOUNT_KICKBACK_DETAILS = "getAccountKickBackDetails",
  ACCOUNT_KICKBACK_INSERT = "accountKickBackInsert",
  ACCOUNT_KICKBACK_EDIT = "accountKickBackEdit",
  ACCOUNT_KICKBACK_DELETE = "accountKickBackDelete",
  ACCOUNT_KICKBACK_ORDER = "accountKickBackOrder",

  FLIGHT_SUPPLIER_PROFITS_LIST = "getFlightSupplierProfitsList",
  FLIGHT_SUPPLIER_PROFITS_DETAILS = "getFlightSupplierProfitsDetails",
  FLIGHT_SUPPLIER_PROFITS_INSERT = "flightSupplierProfitsInsert",
  FLIGHT_SUPPLIER_PROFITS_EDIT = "flightSupplierProfitsEdit",
  FLIGHT_SUPPLIER_PROFITS_DELETE = "flightSupplierProfitsDelete",

  EMAIL_SENTS_LIST = "getEmailSentsList",

  ACCOUNT_SALE_LIST = "getAccountSaleList",
  PRODUCT_SALE_LIST = "getProductSaleList",
  ACCOUNT_MOUNTHLY_LIST = "getAccountMounthlyList",
  HOTEL_FORCAST_LIST = "getHotelForCastList",

  ACCOUNT_GROUP_LIST = "accountGroupList",
  ACCOUNT_GROUP_DETAILS = "accountGroupDetails",
  ACCOUNT_GROUP_INSERT = "accountGroupInsert",
  ACCOUNT_GROUP_EDIT = "accountGroupEdit",
  ACCOUNT_GROUP_DELETE = "accountGroupDelete",

  LOG_MAINS_LIST = "logMainsList",


}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  SET_FILE_UPLOAD = "setFileUpload",
  SET_FILES_LIST = "setFilesList",
  SET_FILES_DETAILS = "setFilesDetails",
  SET_FILES_SELECTED = "setFilesSelected",
  FILES_ERROR = "setFilesErrors",
  SET_EXCEL_PARSE_TITLE = "setExcelParseTitle",
  SET_EXCEL_PARSE_RESULT = "setExcelParseResult",
  EXCEL_PARSE_ERROR = "setExcelParseErrors",
  SET_USER_LIST = "setUserList",
  SET_USER_DETAILS = "setUserDetails",
  USER_ERROR = "setUserErrors",

  SET_ACCOUNT_LIST = "setAccountList",
  SET_ACCOUNT_DETAILS = "setAccountDetails",
  ACCOUNT_ERROR = "setAccountErrors",

  SET_ACCOUNT_PRODUCT_LIST = "setAccountProductList",
  SET_ACCOUNT_PRODUCT_DETAILS = "setAccountProductDetails",
  ACCOUNT_PRODUCT_ERROR = "setAccountProductErrors",

  SET_HOTEL_LIST = "setHotelList",
  SET_HOTEL_LIST_TO = "setHotelListTo",
  SET_HOTEL_DETAILS = "setHotelDetails",
  HOTEL_ERROR = "setHotelErrors",
  SET_HOTEL_ROOMS_LIST = "setHotelRoomsList",
  SET_HOTEL_ROOMS_LIST2 = "setHotelRoomsList2",
  SET_HOTEL_ROOMS_DETAILS = "setHotelRoomsDetails",
  HOTEL_ROOMS_ERROR = "setHotelRoomsErrors",
  SET_HOTEL_CONCEPTS_LIST = "setHotelConceptsList",
  SET_HOTEL_CONCEPTS_LIST2 = "setHotelConceptsList2",
  SET_HOTEL_CONCEPTS_DETAILS = "setHotelConceptsDetails",
  HOTEL_CONCEPTS_ERROR = "setHotelConceptsErrors",
  SET_HOTEL_ROOM_TYPES_LIST = "setHotelRoomTypesList",
  SET_HOTEL_ROOM_TYPES_DETAILS = "setHotelRoomTypesDetails",
  HOTEL_ROOM_TYPES_ERROR = "setHotelRoomTypesErrors",
  SET_HOTEL_FACTSHEETS_LIST = "setHotelFactSheetsList",
  SET_HOTEL_FACTSHEETS_DETAILS = "setHotelFactSheetsDetails",
  HOTEL_FACTSHEETS_ERROR = "setHotelFactSheetsErrors",
  SET_HOTEL_FACTSHEET_ITEMS_LIST = "setHotelFactSheetItemsList",
  SET_HOTEL_FACTSHEET_ITEMS_SELECTED = "setHotelFactSheetItemsSelectedList",
  SET_HOTEL_FACTSHEET_ITEMS_DETAILS = "setHotelFactSheetItemsDetails",
  HOTEL_FACTSHEET_ITEMS_ERROR = "setHotelFactSheetItemsErrors",
  SET_HOTEL_CONCEPT_TYPES_LIST = "setHotelConceptTypesList",
  SET_HOTEL_CONCEPT_TYPES_DETAILS = "setHotelConceptTypesDetails",
  HOTEL_CONCEPT_TYPES_ERROR = "setHotelConceptTypesErrors",
  SET_HOTEL_OVER_COMMISSION_LIST = "setHotelOverCommissionList",
  SET_HOTEL_OVER_COMMISSION_DETAILS = "setHotelOverCommissionDetails",
  HOTEL_OVER_COMMISSION_ERROR = "setHotelOverCommissionErrors",

  SET_HOTEL_PROFITS_LIST = "setHotelProfitsList",
  SET_HOTEL_PROFITS_DETAILS = "setHotelProfitsDetails",
  HOTEL_PROFITS_ERROR = "setHotelProfitsErrors",

  SET_HOTEL_KICKBACKS_LIST = "setHotelKickBacksList",
  SET_HOTEL_KICKBACKS_DETAILS = "setHotelKickBacksDetails",
  HOTEL_KICKBACKS_ERROR = "setHotelKickBacksErrors",

  SET_HOTEL_AVAILABLE_LIST = "setHotelAvailableList",
  SET_HOTEL_AVAILABLE_STATUS = "setHotelAvailableStatus",
  SET_HOTEL_AVAILABLE_DETAILS = "setHotelAvailableDetails",
  SET_HOTEL_AVAILABLE_ADD_DATES = "setHotelAvailableAddDates",
  HOTEL_AVAILABLE_ERROR = "setHotelAvailableErrors",

  SET_HOTEL_ALLOCATIONS_LIST = "setHotelAllocationsList",
  SET_HOTEL_ALLOCATIONS_DETAILS = "setHotelAllocationsDetails",
  HOTEL_ALLOCATIONS_ERROR = "setHotelAllocationsErrors",

  SET_HOTEL_AD_LIST = "setHotelAdList",
  SET_HOTEL_AD_DETAILS = "setHotelAdDetails",
  HOTEL_AD_ERROR = "setHotelAdErrors",

  SET_HOTEL_ACTIONS_LIST = "setHotelActionsList",
  SET_HOTEL_ACTIONS_DETAILS = "setHotelActionsDetails",
  HOTEL_ACTIONS_ERROR = "setHotelActionsErrors",

  SET_HOTEL_ROOM_PRICE_LIST = "setHotelRoomPriceList",
  SET_HOTEL_ROOM_PRICE_DETAILS = "setHotelRoomPriceDetails",
  HOTEL_ROOM_PRICE_ERROR = "setHotelRoomPriceErrors",

  SET_PAYMENTS_LIST = "setPaymentsList",
  SET_PAYMENTS_DETAILS = "setPaymentsDetails",
  SET_PAYMENTS_ERROR = "setPaymentsErrors",

  SET_REMAKRS_LIST = "setRemarksList",
  SET_REMAKRS_DETAILS = "setRemarksDetails",
  SET_REMAKRS_ERROR = "setRemarksErrors",

  SET_RESERVATION_PAYMENTS_LIST = "setReservationPaymentsList",
  SET_RESERVATION_PAYMENTS_DETAILS = "setReservationPaymentsDetails",
  SET_RESERVATION_PAYMENTS_ERROR = "setReservationPaymentsErrors",

  SET_FLIGHT_LIST = "setFlightList",
  SET_FLIGHT_DETAILS = "setFlightDetails",
  SET_FLIGHT_ERROR = "setFlightErrors",

  SET_FLIGHT_RESERVATION_STEPS_BASKET = "setFlightReservationStepsBasket",
  SET_FLIGHT_RESERVATION_SEARCH_PARAMS = "setFlightReservationSearchParams",
  SET_FLIGHT_RESERVATION_LIST = "setFlightReservationList",
  SET_FLIGHT_RESERVATION_ADD = "setFlightReservationAdd",
  SET_FLIGHT_RESERVATION_ADD_INSTALLMENT = "setFlightReservationAddInstallment",
  SET_FLIGHT_RESERVATION_PACKAGE_RESULT = "setFlightReservationPackageResult",
  SET_FLIGHT_RESERVATION_SUMMARY_HOTEL_RATE = "setFlightReservationSummaryHotelRate",
  SET_FLIGHT_RESERVATION_TRANSFERS = "setFlightReservationTransfers",
  SET_FLIGHT_RESERVATION_MAKE_RESERVATION = "setFlightReservationMakeReservation",
  SET_FLIGHT_RESERVATION_SEARCH_CODE = "setFlightReservationSearchCode",
  SET_FLIGHT_RESERVATION_CUR_STEP = "setFlightReservationCurStep",
  SET_FLIGHT_RESERVATION_CUR_NEXT = "setFlightReservationCurNext",
  SET_FLIGHT_RESERVATION_CUR_PREV = "setFlightReservationCurPrev",

  SET_FLIGHT_RESERVATION_IS_SEARCHING = "setFlightReservationIsSearching",

  SET_COMPANY_LIST = "setCompanyList",
  SET_COMPANY_BRANCHES_LIST = "setCompanyBranchesList",
  SET_COMPANY_DEPARTMENTS_LIST = "setCompanyDepartmentsList",
  SET_HOTEL_CHAIN_LIST = "setHotelChainList",
  SET_FACTSHEETS_LIST = "setFactSheetsList",
  SET_FACTSHEETS_DETAILS = "setFactSheetsDetails",
  SET_FACTSHEETS_SELECTED = "setFactSheetsSelected",
  FACTSHEETS_ERROR = "setFactSheetsErrors",
  SET_FACTSHEET_ITEMS_LIST = "setFactSheetItemsList",
  SET_FACTSHEET_ITEMS_DETAILS = "setFactSheetItemsDetails",
  SET_FACTSHEET_ITEMS_SELECTED = "setFactSheetItemsSelected",
  FACTSHEET_ITEMS_ERROR = "setFactSheetItemsErrors",
  SET_ACCOUNT_TAGS_LIST = "setAccountTagsList",
  SET_ACCOUNT_TAGS_DETAILS = "setAccountTagsDetails",
  ACCOUNT_TAGS_ERROR = "setAccountTagsErrors",
  SET_RESERVATION_TAGS_LIST = "setReservationTagsList",
  SET_RESERVATION_TAGS_DETAILS = "setReservationTagsDetails",
  RESERVATION_TAGS_ERROR = "setReservationTagsErrors",

  SET_PRICE_LISTS_LIST = "setPriceListsList",
  SET_PRICE_LISTS_DETAILS = "setPriceListsDetails",
  SET_PRICE_LISTS_SELECTED = "setPriceListsSelectedList",
  PRICE_LISTS_ERROR = "setPriceListsErrors",
  SET_MICE_REQUESTS_LIST = "setMiceRequestsList",
  SET_MICE_REQUESTS_DETAILS = "setMiceRequestsDetails",
  SET_MICE_REQUESTS_SELECTED = "setMiceRequestsSelectedList",
  MICE_REQUESTS_ERROR = "setMiceRequestsErrors",
  SET_PRICE_LIST_ITEMS_LIST = "setPriceListItemsList",
  SET_PRICE_LIST_ITEMS_DETAILS = "setPriceListItemsDetails",
  SET_PRICE_LIST_ITEMS_SELECTED = "setPriceListsSelectedList",
  PRICE_LIST_ITEMS_ERROR = "setPriceListItemsErrors",
  SET_SUPPLIERS_LIST = "setSuppliersList",
  SET_SUPPLIERS_DETAILS = "setSuppliersDetails",
  SUPPLIERS_ERROR = "setSuppliersErrors",
  SET_AIRLINE_LIST = "setAirlineList",
  SET_AIRLINE_DETAILS = "setAirlineDetails",
  AIRLINE_ERROR = "setAirlineErrors",
  SET_AIRLINE_CLASSES_LIST = "setAirlineClassesList",
  SET_AIRLINE_CLASSES_DETAILS = "setAirlineClassesDetails",
  AIRLINE_CLASSES_ERROR = "setAirlineClassesErrors",
  SET_AIRPORTS_LIST = "setAirportsList",
  SET_AIRPORTS_DETAILS = "setAirportsDetails",
  AIRPORTS_ERROR = "setAirportsErrors",
  SET_FLIGHT_EXTRA_LIST = "setFlightExtrasList",
  SET_FLIGHT_EXTRA_DETAILS = "setFlightExtrasDetails",
  FLIGHT_EXTRA_ERROR = "setFlightExtrasErrors",
  SET_BANKS_LIST = "setBanksList",
  SET_BANKS_DETAILS = "setBanksDetails",
  BANKS_ERROR = "setBanksErrors",
  SET_BANKPOS_LIST = "setBankPosList",
  SET_BANKPOS_DETAILS = "setBankPosDetails",
  BANKPOS_ERROR = "setBankPosErrors",
  SET_BANKPOS_INSTALLMENT_LIST = "setBankPosInstallmentList",
  SET_BANKPOS_INSTALLMENT_DETAILS = "setBankPosInstallmentDetails",
  BANKPOS_INSTALLMENT_ERROR = "setBankPosInstallmentErrors",
  SET_BANKACCOUNTS_LIST = "setBankAccountsList",
  SET_BANKACCOUNTS_DETAILS = "setBankAccountsDetails",
  BANKACCOUNTS_ERROR = "setBankAccountsErrors",
  SET_COMPANY_CREDIT_CARD_LIST = "setCompanyCreditCardList",
  SET_COMPANY_CREDIT_CARD_DETAILS = "setCompanyCreditCardDetails",
  COMPANY_CREDIT_CARD_ERROR = "setCompanyCreditCardErrors",
  SET_CUR_RATE_LIST = "setCurRateList",
  SET_CUR_RATE_DETAILS = "setCurRateDetails",
  CUR_RATE_ERROR = "setCurRateErrors",
  /***********RESERVATIONS START**************/
  /***********RESERVATIONS START**************/
  /***********RESERVATIONS START**************/
  /***********RESERVATIONS START**************/
  SET_RESERVATIONS_LIST = "setReservationsList",
  SET_RESERVATIONS_LIST2 = "setReservationsList2",
  SET_RESERVATIONS_DETAILS = "setReservationsDetails",
  RESERVATIONS_ERROR = "setReservationsErrors",

  SET_RESERVATION_PRODUCTS_LIST = "setReservationProductsList",
  SET_RESERVATION_PRODUCTS_LIST_2 = "setReservationProductsList2",
  SET_RESERVATION_PRODUCTS_CONFIRM_LIST = "setReservationProductsConfirmList",
  SET_RESERVATION_PRODUCTS_DETAILS = "setReservationProductsDetails",
  SET_RESERVATION_PRODUCTS_INSERT_MANUAL = "setReservationProductsInsertManual",
  RESERVATION_PRODUCTS_ERROR = "setReservationProductsErrors",
  /***********RESERVATIONS END**************/
  /***********RESERVATIONS END**************/
  /***********RESERVATIONS END**************/
  /***********RESERVATIONS END**************/

  /*****MAKE MANUEl RESERVATIONS START****/
  /*****MAKE MANUEl RESERVATIONS START****/
  /*****MAKE MANUEl RESERVATIONS START****/
  SET_MAKE_MANUEL_RESERVATIONS_DETAILS = "setMakeManuelReservations",
  /*****MAKE MANUEl RESERVATIONS END****/
  /*****MAKE MANUEl RESERVATIONS END****/
  /*****MAKE MANUEl RESERVATIONS END****/

  SET_TAGS_LIST = "setTagsList",
  SET_TAGS_DETAILS = "setTagsDetails",
  TAGS_ERROR = "setTagsErrors",
  SET_REGIONS_LIST = "setRegionsList",
  SET_MARKETS_LIST = "setMarketsList",
  SET_MARKETS_LIST_CACHE = "setMarketsListCache",
  SET_LANGUAGES_LIST = "setLanguagesList",
  SET_LANGUAGES_LIST_CACHE = "setLanguagesListCache",
  SET_COMPANY_TITLES_LIST = "setCompanyTitlesList",
  SET_CITY_LIST = "setCityList",
  SET_COUNTRY_LIST = "setCountryList",
  SET_EDITOR_CONTENT = "setEditorContent",
  SET_SYSTEM_USER_GROUP_LIST = "setSystemUserListGroup",

  /************************/
  SET_HOTEL_TEST_LIST = "setHotelTestList",
  SET_HOTEL_TEST_DETAILS = "setHotelTestDetails",
  HOTEL_TEST_ERROR = "setHotelTestErrors",
  /**********************/

  SET_TRANSFER_OPERATIONS_LIST = "setTransferOperationsList",
  SET_TRANSFER_OPERATIONS_DETAILS = "setTransferOperationsDetails",
  SET_TRANSFER_OPERATIONS_ERROR = "setTransferOperationsErrors",

  SET_TRANSFERS_LIST = "setTransfersList",
  SET_TRANSFERS_DETAILS = "setTransfersDetails",
  TRANSFERS_ERROR = "setTransfersErrors",

  SET_TRANSFER_COMPANY_LIST = "setTransferCompanyList",
  SET_TRANSFER_COMPANY_DETAILS = "setTransferCompanyDetails",
  TRANSFER_COMPANY_ERROR = "setTransferCompanyErrors",
  SET_TRANSFER_CARS_LIST = "setTransferCarsList",
  SET_TRANSFER_CARS_DETAILS = "setTransferCarsDetails",
  TRANSFER_CARS_ERROR = "setTransferCarsErrors",
  SET_TRANSFER_PRICES_LIST = "setTransferPricesList",
  SET_TRANSFER_PRICES_DETAILS = "setTransferPricesDetails",
  TRANSFER_PRICES_ERROR = "setTransferPricesErrors",
  SET_TRANSFER_LOCATIONS_LIST = "setTransferLocationsList",
  SET_TRANSFER_LOCATIONS_DETAILS = "setTransferLocationsDetails",
  TRANSFER_LOCATIONS_ERROR = "setTransferLocationsErrors",
  SET_TRANSFER_CARCOSTS_LIST = "setTransferCarCostsList",
  SET_TRANSFER_CARCOSTS_DETAILS = "setTransferCarCostsDetails",
  TRANSFER_CARCOSTS_ERROR = "setTransferCarCostsErrors",

  SET_INVOICES_LIST = "setInvoicesList",
  SET_INVOICES_DETAILS = "setInvoicesDetails",
  SET_INVOICES_GETVOUCHERS = "setInvoicesGetVouchers",
  INVOICES_ERROR = "setInvoicesErrors",

  SET_INVOICE_RETURNS_LIST = "setInvoiceReturnsList",
  SET_INVOICE_RETURNS_DETAILS = "setInvoiceReturnsDetails",
  INVOICE_RETURNS_ERROR = "setInvoiceReturnsErrors",

  SET_ACCOUNTING_CODE_LIST = "setAccountingCodeList",
  SET_ACCOUNTING_CODE_DETAILS = "setAccountingCodeDetails",
  ACCOUNTING_CODE_ERROR = "setAccountingCodeErrors",

  SET_INVOICE_ITEMS_LIST = "setInvoiceItemsList",
  SET_INVOICE_ITEMS_DETAILS = "setInvoiceItemsDetails",
  INVOICE_ITEMS_ERROR = "setInvoiceItemsErrors",

  SET_VAT_RATES_LIST = "setVatRatesList",
  SET_VAT_RATES_DETAILS = "setVatRatesDetails",
  VAT_RATES_ERROR = "setVatRatesErrors",

  SET_ACCOUNTING_COST_CENTERS_LIST = "setAccountingCostCentersList",
  SET_ACCOUNTING_COST_CENTERS_DETAILS = "setAccountingCostCentersDetails",
  ACCOUNTING_COST_CENTERS_ERROR = "setAccountingCostCentersErrors",


  SET_ACCOUNT_KICKBACK_LIST = "setAccountKickBackList",
  SET_ACCOUNT_KICKBACK_DETAILS = "setAccountKickBackDetails",
  ACCOUNT_KICKBACK_ERROR = "setAccountKickBackErrors",

  SET_FLIGHT_SUPPLIER_PROFITS_LIST = "setFlightSupplierProfitsList",
  SET_FLIGHT_SUPPLIER_PROFITS_DETAILS = "setFlightSupplierProfitsDetails",
  FLIGHT_SUPPLIER_PROFITS_ERROR = "setFlightSupplierProfitsErrors",

  SET_EMAIL_SENTS_LIST = "setEmailSentsList",

  SET_ACCOUNT_SALE_LIST = "setAccountSaleList",
  SET_PRODUCT_SALE_LIST = "setProductSaleList",
  SET_ACCOUNT_MOUNTHLY_LIST = "setAccountMounthlyList",
  SET_HOTEL_FORCAST_LIST = "setHotelForCastList",

  SET_ACCOUNT_GROUP_LIST = "setAccountGroupList",
  SET_ACCOUNT_GROUP_DETAILS = "setAccountGroupDetails",
  ACCOUNT_GROUP_ERROR = "setAccountGroupErrors",

  SET_LOG_MAINS_LIST = "setLogMainsList",

}

export { Actions, Mutations };
